exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-[id]-js": () => import("./../../../src/pages/services/[id].js" /* webpackChunkName: "component---src-pages-services-[id]-js" */),
  "component---src-pages-services-cyber-security-consultancy-js": () => import("./../../../src/pages/services/cyber-security-consultancy.js" /* webpackChunkName: "component---src-pages-services-cyber-security-consultancy-js" */),
  "component---src-pages-services-cyber-security-tools-js": () => import("./../../../src/pages/services/cyber-security-tools.js" /* webpackChunkName: "component---src-pages-services-cyber-security-tools-js" */),
  "component---src-pages-services-osint-analytics-js": () => import("./../../../src/pages/services/osint-analytics.js" /* webpackChunkName: "component---src-pages-services-osint-analytics-js" */),
  "component---src-pages-services-penetration-testing-js": () => import("./../../../src/pages/services/penetration-testing.js" /* webpackChunkName: "component---src-pages-services-penetration-testing-js" */),
  "component---src-pages-services-red-team-as-a-service-js": () => import("./../../../src/pages/services/red-team-as-a-service.js" /* webpackChunkName: "component---src-pages-services-red-team-as-a-service-js" */)
}

